import { LENDER_VIEW, PRINCIPAL_KANBAN_VIEW, PRINCIPAL_VIEW } from 'src/constants/person';

import { useUser } from './use-user';

type DashboardLayout = typeof LENDER_VIEW | typeof PRINCIPAL_VIEW | typeof PRINCIPAL_KANBAN_VIEW;


export const useDashboardLayout = (): DashboardLayout => {
    const { isLender, onboardingNeeded } = useUser();

    // if user is a lender, return lender view
    if (isLender) {
        return LENDER_VIEW;
    }
    // if user is a principal and onboarding is needed, return principal view
    if (onboardingNeeded) {
        return PRINCIPAL_VIEW;
    }
    // otherwise, return principal kanban view
    return PRINCIPAL_KANBAN_VIEW;
}
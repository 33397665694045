import React from "react";
import { useUser } from "src/hooks/use-user";

import { BorrowerOnboardingReducer, BorrowerOnboardingReducerInitialState, OnboardingStep } from "./borrower-onboarding.reducer";

export const useBorrowerOnboarding = () => {
    const [state, dispatch] = React.useReducer(BorrowerOnboardingReducer, BorrowerOnboardingReducerInitialState)
    const userState = useUser();

    const handleStepChange = (step: OnboardingStep) => {
        dispatch({
            type: 'SET_ACTIVE_STEP',
            payload: step
        })
    }

    const handleGoToNext = async () => {
        if (state.activeStep === "DRIVERS_LICENSE") {
            dispatch({
                type: 'SET_ACTIVE_STEP',
                payload: "SOFT_CREDIT_CONSENT"
            })
        }
    }

    return {
        state,
        onboardingNeeded: userState.onboardingNeeded,
        onStepChange: handleStepChange,
        onGoToNext: handleGoToNext
    } as const;
};
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from 'components/dist/atoms/Button';
import Text from 'components/dist/atoms/Text';
import { useFormik } from 'formik';
import { CardStatus } from 'src/constants/loan';
import { useUser } from 'src/hooks/use-user';
import { useAddSoftCreditPullMutation } from 'src/services/userApi';
import { getLoggedInUser } from 'src/slices/profile';
import { useDispatch } from 'src/store';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { schema } from './soft-credit-check-form.validation';


export const SoftCreditCheckForm = () => {
    const [addSoftCreditPull] = useAddSoftCreditPullMutation();
    const dispatch = useDispatch();
    const { user, lenders } = useUser();
    const formik = useFormik({
        initialValues: {
            signature: '',
            consent: CardStatus.PROCESSED,
            userId: user.id,
            lenderId: lenders?.[0]?.id
        },
        validationSchema: schema,
        onSubmit: async (values, formikHelpers): Promise<void> => {
            formikHelpers.setSubmitting(true);
            await addSoftCreditPull({ userId: user.id, payload: values });
            dispatch(getLoggedInUser());
            formikHelpers.setSubmitting(false);
        }
    });
    return (<form className="flex flex-col divide-y border border-gray-neutral-80 rounded-md" onSubmit={formik.handleSubmit}>
        <div className='p-4 '>
            <div>
                <Text weight="medium" size="sm">Soft Credit Check Consent</Text>
                <Text size="xs" variant="secondary">Soft credit pull will not affect your credit score</Text>
            </div>
            <Text size="sm" className="pb-6" as="div">
                <Text size="sm" className="pt-4">
                    I, <Text size="sm" weight="medium" color='primary' as="span">{getUserDisplayName(user)}</Text>,
                    hereby authorize <Text size="sm" weight="medium" variant="blue" as="span">{lenders?.[0].name}</Text> to verify my past and present employment, earnings records,
                    bank accounts, stockholdings, and any other asset balances that are needed to
                    process my commercial loan application. I further authorize under the Fair Credit
                    Reporting Act <Text size="sm" weight="medium" variant="blue" as="span">{lenders?.[0].name}</Text> to order my consumer credit report from a consumer reporting
                    agency and verify other credit or personal background information, including past
                    and present mortgage and landlord references.
                </Text>
                <Text size="sm" className="pt-4">
                    The information <Text size="sm" weight="medium" variant="blue" as="span">{lenders?.[0].name}</Text> obtains is only to be used in the processing of my
                    commercial loan application for a commercial loan. <Text size="sm" weight="medium" variant="blue" as="span">{lenders?.[0].name}</Text> is also authorized
                    to share my personal information (but not my consumer report) with other financial
                    institutions.
                </Text>
                <Text size="sm" className="pt-4">
                    By signing below, the undersigned agrees to all the terms and conditions.
                </Text>
            </Text>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <TextField
                        autoFocus
                        error={Boolean(formik.touched.signature && formik.errors.signature)}
                        fullWidth
                        helperText={formik.touched.signature && formik.errors.signature}
                        label="Initials"
                        name="signature"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.signature}
                        variant="outlined"
                        inputProps={{
                            style: { textTransform: "uppercase" },
                            "data-testid": "signature"
                        }}
                    />

                </Grid>
            </Grid>
        </div>
        <div className='p-4 '>
            {Object.keys(formik.errors).length > 0 && <Alert severity='error' sx={{ mt: 3 }}>
                There are items that require your attention.
            </Alert>}
            <div className='mt-6' >
                <Button
                    onClick={() => formik.handleSubmit()}
                    loading={formik.isSubmitting}
                    type="submit"
                    data-testid='submit-btn'>
                    Submit
                </Button>
            </div>
        </div>
    </form>
    );
};

export default SoftCreditCheckForm;

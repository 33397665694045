import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { OnboardingStep } from '../borrower-onboarding/borrower-onboarding.reducer';

type PrincipalFormsStepperProps = {
    steps: OnboardingStep[],
    activeStep: OnboardingStep,
    isCompleted?: boolean,
    isProcessing?: boolean,
    onChange: (label: OnboardingStep) => void
}

const stepLabels = {
    ["DRIVERS_LICENSE"]: 'Upload Driver License',
    ["SOFT_CREDIT_CONSENT"]: 'Soft Credit Check',
}

export const PrincipalFormsStepper = ({ isProcessing, isCompleted, steps, activeStep, onChange }: PrincipalFormsStepperProps) => {

    const theme = useTheme();
    const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (<Box sx={{
        backgroundColor: 'background.default',
        borderRadius: 1,
        p: 2,
        mb: 4
    }}>
        <Stepper
            data-test-id='applicant-form-stepper'
            orientation={isSmAndUp ? 'horizontal' : 'vertical'}
            sx={{
                width: {
                    xs: '100%',
                    sm: '50%',
                    md: '40%',
                    lg: '35%',
                    xl: '35%'
                }
            }}
            nonLinear>
            {steps.map((label) => {
                return (
                    <Step
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                        key={label}
                        aria-label={label}
                        onClick={() => onChange(label)}>
                        <StepLabel sx={{ pr: 2 }} >
                            {stepLabels[label]}
                        </StepLabel>
                        {isCompleted && <Check color='secondary' />}
                        {isProcessing && <CircularProgress size={20} />}
                    </Step>
                );
            })}
        </Stepper>
    </Box>)
}
import MuiContainer from '@mui/material/Container';
import MuiLinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { themeV2 } from 'src/theme/mysherpas-theme-option';

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
}));

const Container = styled(MuiContainer)(({ theme }) => ({

}));

const Heading = styled('h1')(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    color: '#000',
    fontWeight: 400,
    margin: 0,
    fontSize: theme.typography.pxToRem(28),
}));

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
    height: 8,
    backgroundColor: themeV2.neutral.grey.default,
    borderRadius: 8,
    '& .MuiLinearProgress-bar': {
        backgroundColor: themeV2.colors.green[50],
    }
}));

const LinearProgressContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
}));

const LinearProgressLabel = styled('h6')(({ theme }) => ({
    fontWeight: 400,
    paddingBottom: theme.spacing(1),
    margin: 0,
    fontSize: theme.typography.pxToRem(12),
    color: themeV2.colors.blue[900],
}));

export const BorrowerOnboardingStyles = {
    Wrapper,
    Container,
    Heading,
    LinearProgress,
    LinearProgressContainer,
    LinearProgressLabel
}
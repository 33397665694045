export type OnboardingStep = 'DRIVERS_LICENSE' | 'SOFT_CREDIT_CONSENT' | 'COMPLETE';
interface StepsState {
    activeStep: OnboardingStep;
    steps: OnboardingStep[];
}

type Action = { type: 'SET_ACTIVE_STEP', payload: OnboardingStep }

export const BorrowerOnboardingReducerInitialState: StepsState = {
    activeStep: "DRIVERS_LICENSE",
    steps: ["DRIVERS_LICENSE", "SOFT_CREDIT_CONSENT"],
}

export const BorrowerOnboardingReducer = (state: StepsState, action: Action) => {
    switch (action.type) {
        case 'SET_ACTIVE_STEP':
            return {
                ...state,
                activeStep: action.payload
            };
        default:
            return state;
    }
}
import Text from 'components/dist/atoms/Text';

type PropsType = {
    title: string;
    subTitle: string;
}

export const CongratulationCard = ({ title, subTitle }: PropsType) => <div className='p-4 rounded-md border border-gray-neutral-80'>
    <Text size="sm">
        {title}
    </Text>
    <Text size="xs" variant="secondary" >
        {subTitle}
    </Text>
</div>
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import React from "react";
import { PrincipalFormsStepper } from 'src/components/loans/principal-forms-stepper';

import { CongratulationCard } from "../applicant/congratulation-card";
import SoftCreditCheckForm from "../applicant/soft-credit-check-form/soft-credit-check-form";
import { UploadDriverLicense } from "../applicant/upload-driver-license";
import { useBorrowerOnboarding } from "./borrower-onboarding.hook";
import { BorrowerOnboardingStyles } from "./borrower-onboarding.styles";

export const BorrowerOnboarding = () => {
    const { state, onStepChange, onGoToNext, onboardingNeeded } = useBorrowerOnboarding();

    return <Stack className='bg-white pt-2'>
        <Stack space="default" className='pb-3'>
            <Text weight="medium" className="px-4">
                Onboarding
            </Text>
            <Separator />
        </Stack>
        <BorrowerOnboardingStyles.Wrapper>
            <BorrowerOnboardingStyles.Container maxWidth="xl">
                <PrincipalFormsStepper
                    steps={state.steps}
                    activeStep={state.activeStep}
                    onChange={onStepChange} />
                {(onboardingNeeded && state.activeStep === "DRIVERS_LICENSE") &&
                    <UploadDriverLicense
                        onGoToNext={onGoToNext}
                    />}
                {onboardingNeeded && state.activeStep === 'SOFT_CREDIT_CONSENT' &&
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 lg:col-start-3 lg:col-span-8'>
                            <SoftCreditCheckForm />
                        </div>
                    </div>}
                {(!onboardingNeeded) &&
                    <CongratulationCard
                        title="Congratulations" subTitle="You are now ready." />}
            </BorrowerOnboardingStyles.Container>
        </BorrowerOnboardingStyles.Wrapper>
    </Stack>
};
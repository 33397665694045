import { useRouter } from "next/router";
import { useEffect } from "react";
import { PRINCIPAL_KANBAN_VIEW } from "src/constants/person";
import { Route } from "src/constants/ui";

import { useDashboardLayout } from "./use-dashboard-layout";
import { useLoans } from "./use-loans";
import { useUser } from "./use-user";

export const useRedirectBorrower = () => {
    const { isBorrower, onboardingNeeded } = useUser();
    const router = useRouter();
    const dashboardViewType = useDashboardLayout();
    const { allLoans, isLoading } = useLoans();
    const shouldRedirectToLoan = isBorrower &&
        !isLoading &&
        allLoans.length === 1 &&
        dashboardViewType === PRINCIPAL_KANBAN_VIEW;

    useEffect(() => {
        if (shouldRedirectToLoan) {
            router.replace({
                pathname: Route.SINGLE_LOAN,
                query: {
                    loanId: allLoans[0].id
                }
            });
        }
    }, [shouldRedirectToLoan, allLoans, router, onboardingNeeded]);

    return {
        isLoading: isLoading || !router.isReady,
    }
}